import type { JSX } from 'react';
import * as prismicH from '@prismicio/helpers';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type ProductsCarouselProps =
  SliceComponentProps<Content.ProductsCarouselSlice>;

import { Products } from '@/components';
import { SORT_BY_MAP, stringToSlug } from '@/utils';
import { SortBySelectOption } from '@/types';

const ProductsCarousel = ({
  slice,
}: ProductsCarouselProps): JSX.Element | null => {
  // eslint-disable-next-line no-case-declarations
  const {
    see_all_link,
    see_all_text,
    title,
    limit,
    sort_by,
    type, // filter by category
    filter_by_brand, // filter by brand
    product_group_id, // filter by group id
    filter_by_on_sale, // filter by on sale
    filter_by_store, // filter by store
    bottom_spacing,
    padding_section,
  } = slice.primary;

  const carouselListTitle = prismicH.asText(title) ?? 'Products';

  const paddingSection = padding_section;

  const analyticsListName = stringToSlug(carouselListTitle);

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Products
      analyticsListName={analyticsListName}
      filterByBrand={filter_by_brand as string}
      filterByCategory={type as string}
      filterByGroupId={product_group_id as string}
      filterByOnSale={filter_by_on_sale}
      filterByStore={filter_by_store}
      limit={limit!}
      paddingSection={paddingSection}
      products={(slice as any).products}
      seeAllHref={see_all_link ?? '/'}
      seeMoreText={see_all_text as string}
      sortBy={SORT_BY_MAP?.[sort_by] as SortBySelectOption}
      spacingBottom={bottom_spacing}
      title={carouselListTitle}
      totalCount={(slice as any).totalCount}
    />
  );
};

export default ProductsCarousel;
